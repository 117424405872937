<!--
   无此界面

   @date:2022/09/07
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>
-->
<style lang="less">
#err-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  img {
    width: 300px;
  }
  .info {
    margin-top: 20px;
    font-size: 16px;
    color: @textColorLight;
  }
  .btn {
    font-size: 20px;
    margin-top: 30px;
    width: 200px;
    line-height: 36px;
    text-align: center;
    background-color: @buttonColorPrimary;
    color: @textColorFlip;
    border-radius: 36px;
    cursor: pointer;
  }
}
</style>

<template>
  <div id="err-404">
    <img
      src="/bg/light-bg-404.png"
      alt="无界面"
    >
    <div class="info">
      无此界面
    </div>
    <div
      class="btn"
      @click.stop="_go"
    >
      返回首页
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {};
  },
  methods: {
    _go () {
      this.$router.replace({ name: 'dashboard_index' });
    }
  }
};
</script>
